<template>
  <b-modal
    v-if="dismissData"
    id="modal-dismiss"
    :title="`Dismiss contract ${$moment(dismissData.start_date).format('DD.MM.YYYY')} - ${$moment(dismissData.start_date).format('DD.MM.YYYY')} for `"
    centered
    ref="modalDismiss"
    title-class="font-22"
    hide-footer
    @hidden="clearData"
    @shown="setData"
  >
    <b-form @submit.prevent="handleSubmit">
      <b-form-group
        label="Dismissed Date"
        label-for="dismissed-date"
        class="required"
      >
        <date-picker
          :disabled="disabled"
          v-model="dismissedDate"
          type="date"
          value-type="YYYY-MM-DD"
          format="DD.MM.YYYY"
          :input-attr="{ required: 'required', id: 'dismissed-date'}"
          placeholder="Select Date"
          class="mb-2"
        />
<!--        <b-form-datepicker :disabled="disabled" required id="dismissed-date" v-model="dismissedDate" class="mb-2"></b-form-datepicker>-->
      </b-form-group>
      <b-form-group
        label="Dismiss Note"
        label-for="archived-date"
        class="required"
      >
        <b-form-textarea
          id="dismissed-note"
          v-model="dismissedNote"
          :disabled="disabled"
          required
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          v-if="disabled"
          type="button"
          variant="secondary"
          @click="closeModal"
        >
          Close
        </b-button>
        <b-button
          v-else
          type="submit"
          variant="primary"
        >
          Ok
        </b-button>
      </div>
    </b-form>
  </b-modal>
<!--  dismissed_date-->
<!--  dismiss_note-->
</template>

<script>
import datePicker from 'vue2-datepicker';
export default {
  props: ['dismissData'],
  components: {
    datePicker,
  },
  data() {
    return {
      dismissedDate: '',
      dismissedNote: '',
      disabled: false
    }
  },
  methods: {
    clearData() {
      this.dismissedDate = ''
      this.dismissedNote = ''
    },
    async handleSubmit() {
      let errorString = 'Please, fill required fields: '
      let isValid = true

      if (!this.dismissedDate) {
        errorString = errorString + '<br>' + '- Dismissed Date'
        isValid = false
      }
      if (!this.dismissedNote) {
        errorString = errorString + '<br>' + '- Dismiss Note'
        isValid = false
      }

      if (!isValid) {
        this.$swal.fire('Error!', errorString, 'error')

        return
      }
      await this.$store.dispatch('employees/dismissContract', {
        id: this.dismissData.id,
        data: this.$convertObjectToFormData({
          id: this.dismissData.id,
          dismissed_date: this.dismissedDate,
          dismiss_note: this.dismissedNote,
        })
      })
      this.$store.dispatch('employees/getEmployeeById', this.$route.params.id);
      this.$refs['modalDismiss'].hide()

    },
    setData () {
      this.dismissedNote = this.dismissData.dismiss_note
      this.dismissedDate = this.dismissData.dismissed_date
      if (this.dismissData.dismiss_note) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    closeModal () {
      this.$refs['modalDismiss'].hide()
    }
  },
}
</script>

<style scoped>
</style>
